import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import(
  /* webpackChunkName: "order-builder-ear-impression" */ "./earImpression.scss"
);

const EarImpression = ({
  leftSelected,
  rightSelected,
  setEarImpressionForOrder,
}) => {
  const [rightImpressOnFile, setRightImpressOnFile] = useState(false);
  const [leftImpressOnFile, setLeftImpressOnFile] = useState(false);
  const [rightImpressionForOrder, setRightImpressionForOrder] = useState('mailInAnImpression');
  const [leftImpressionForOrder, setLeftImpressionForOrder] = useState('mailInAnImpression');
  const [earImpressionRightText, setEarImpressionRightText] = useState("");
  const [earImpressionLeftText, setEarImpressionLeftText] = useState("");

  const handleChangeRight = (event) => {
    setRightImpressionForOrder(event.target.value);
  };

  const handleChangeLeft = (event) => {
    setLeftImpressionForOrder(event.target.value);
  };

  useEffect(() => {
    // Checks for changes in component or leftSelected / rightSelected and adds the selected options to an object
    // that is sent to the ite or earmold order builder component.
    let earImpressionForOrder = {};
    // console.log("rightImpressionForOrder", rightImpressionForOrder);
    // console.log("leftImpressionForOrder", leftImpressionForOrder);
    if (rightSelected) {
      switch (rightImpressionForOrder) {
        case 'useAnImpressionOnFile':
          earImpressionForOrder.right = {
            type: 'impressionOnFile',
            serialNumber: earImpressionRightText,
          };
          break;
        case 'useTheScanAttached':
          earImpressionForOrder.right = {
            type: 'scanAttached',
          };
          break;
        default:
          earImpressionForOrder.right = {
            type: 'mailImpression',
          };
      }
    }

    if (leftSelected) {
      switch (leftImpressionForOrder) {
        case 'useAnImpressionOnFile':
          earImpressionForOrder.left = {
            type: 'impressionOnFile',
            serialNumber: earImpressionLeftText,
          };
          break;
        case 'useTheScanAttached':
          earImpressionForOrder.left = {
            type: 'scanAttached',
          };
          break;
        default:
          earImpressionForOrder.left = {
            type: 'mailImpression',
          };
      }
    }
    // console.log("earImpressionForOrder", earImpressionForOrder);
    setEarImpressionForOrder(earImpressionForOrder);
  }, [
    rightImpressionForOrder,
    leftImpressionForOrder,
    earImpressionRightText,
    earImpressionLeftText,
    rightSelected,
    leftSelected,
  ]);

  return (
    <div className="ear-impression-component-main-div order-builder-component">
      <div className="ear-impression-div">
        <div>
          <Row className="order-builder-component-title pb-4">
            <Col xs={4}>
              <h2>Ear Impressions</h2>
            </Col>
            <Col md={4}>
              {rightSelected && (
                <Row>
                  <Col xs={12}><p className="right-ear-title ear-impression-option mb-0"><span className="dot dot-right"></span>Right Ear</p></Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          defaultChecked={true}
                          onChange={handleChangeRight}
                          id="ear-impression-2"
                          type="radio"
                          value="mailInAnImpression"
                          name="earImpressionRight"
                        />
                        Mail in an impression
                      </label>
                      {rightImpressionForOrder === "mailInAnImpression" && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            Upon checkout, you will be able to download an Order
                            Summary document to include in your mailing.
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          onChange={handleChangeRight}
                          id="ear-impression-2"
                          type="radio"
                          value="useAnImpressionOnFile"
                          name="earImpressionRight"
                        />
                        Use an impression on file
                      </label>
                      {rightImpressionForOrder === "useAnImpressionOnFile" && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            <input
                              type="text"
                              name="earImpressionRightText"
                              value={earImpressionRightText}
                              onChange={(event) => {
                                setEarImpressionRightText(event.target.value);
                              }}
                              placeholder="Enter serial number"
                              required
                            />
                          </p>
                          <p>
                            We are only able to use existing impressions for new
                            orders.
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          onChange={handleChangeRight}
                          id="ear-impression-2"
                          type="radio"
                          value="useTheScanAttached"
                          name="earImpressionRight"
                        />
                        Use the scan attached
                      </label>
                      {rightImpressionForOrder === "useTheScanAttached" && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            {`Upon checkout, you will be able to upload your
                            scan files (both .asc or .stl formats accepted).`}
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
            {leftSelected && (
              <Col md={4} className="left-side">
                <Row>
                  <Col xs={12}><p className="left-ear-title ear-impression-option ear-impression-option mb-0"><span className="dot dot-left"></span>Left Ear</p></Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          defaultChecked={true}
                          onChange={handleChangeLeft}
                          id="ear-impression-2"
                          type="radio"
                          value="mailInAnImpression"
                          name="earImpressionLeft"
                        />
                        Mail in an impression
                      </label>
                      {leftImpressionForOrder === "mailInAnImpression" && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            Upon checkout, you will be able to download an Order
                            Summary document to include in your mailing.
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          onChange={handleChangeLeft}
                          id="ear-impression-2"
                          type="radio"
                          value="useAnImpressionOnFile"
                          name="earImpressionLeft"
                        />
                        Use an impression on file
                      </label>
                      {leftImpressionForOrder === "useAnImpressionOnFile" && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            <input
                              type="text"
                              name="earImpressionLeftText"
                              value={earImpressionLeftText}
                              onChange={(event) => {
                                setEarImpressionLeftText(event.target.value);
                              }}
                              placeholder="Enter serial number"
                              required
                            />
                          </p>
                          <p>
                            We are only able to use existing impressions for new
                            orders.
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div className="ear-impression-option">
                      <label>
                        <input
                          onChange={handleChangeLeft}
                          id="ear-impression-2"
                          type="radio"
                          value="useTheScanAttached"
                          name="earImpressionLeft"
                        />
                        Use the scan attached
                      </label>
                      {leftImpressionForOrder === "useTheScanAttached" && (
                        <div className="ear-impression-on-file-input">
                          <p>
                            {`Upon checkout, you will be able to upload your
                            scan files (both .asc or .stl formats accepted).`}
                          </p>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default EarImpression;
