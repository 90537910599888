import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import { OrderConfirmationStatus } from "./store/constants";
import Loading from "../../components/Loading/Loading";
import { parse, isValid, addDays, isWeekend, format, startOfDay, isBefore } from 'date-fns';
import { Link } from "react-router-dom";
import { setErrorStatus } from "../organisations/store/action-creators";
import { sortBy, some, find } from 'lodash';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { OrderStatus } from '../storeHome/store/constants'
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import(/* webpackChunkName: "orderConfirmation" */ "./orderConfirmation.scss");

const OrderConfirmation = ({
  submitOrder,
  orderConfirmationStatus,
  orderConfirmationData,
  sentOrderConfirmationStatus,
  orderNumber,
  cartData,
  addOrderToCart,
  loadOrderConfirmation,
  resetOrderNumber,
  profileData,
  setIsStockOrder,
  isStockOrder,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [orderShippingRate, setOrderShippingRate] = useState("standard");
  const [selectedSite, setSelectedSite] = useState(0);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [requiredByDate, setRequiredByDate] = useState();
  const [siteError, setSiteError] = useState();
  const [siteList, setSiteList] = useState([]);
  const [isHAFSorderChecked, setIsHAFSorderChecked] = useState(false);
  const [isVAClientChecked, setIsVAClientChecked] = useState(false);
  const [hideVACheckbox, setHideVACheckbox] = useState(false);
  const [files, setFiles] = useState([]);
  const [minDate, setMinDate] = useState('');
  const [requiredByDateFocused, setRequiredByDateFocused] = useState(false);
  let history = useHistory();

  useEffect(() => {
    setHideVACheckbox(false);

    if (profileData) {
      if (profileData.IsSpecsavers) {
        setHideVACheckbox(true);
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (isStockOrder === OrderStatus.STOCK_ORDER) {
      setFirstName('Stock');
      setLastName("Order")
    } else {
      setFirstName('');
      setLastName('');
    }
  }, [isStockOrder, OrderStatus]);

  const handleChange = (event) => {
    const myValue = event.target.value;
    setOrderShippingRate(myValue);
  };

  const handleHAFSorderChange = (event) => {
    setIsHAFSorderChecked(event.target.checked);
  };

  const handleVAClientChange = (event) => {
    setIsVAClientChecked(event.target.checked);
  };

  const handleSiteChange = (event) => {
    const myValue = event.target.value;
    //console.log(myValue);
    setSelectedSite(myValue);
  };

  const handleFileChange = (event) => {
    let incorrectFileTypesUploaded = false;
    const selectedFiles = Array.from(event.target.files);
    
    const filteredFiles = selectedFiles.filter(file => {
      // ['.pdf', '.png', '.jpeg', '.jpg'].includes(file.name.toLowerCase().match(/\.[0-9a-z]+$/i)[0])
      if (!['.pdf', '.png', '.jpeg', '.jpg', '.stl', '.asc'].includes(file.name.toLowerCase().match(/\.[0-9a-z]+$/i)[0])) {
        incorrectFileTypesUploaded = true;
        return false;
      }
      else {
        return true;
      }
    }
    );

    if (incorrectFileTypesUploaded) {
      alert("INCORRECT FILE TYPES")
    }

    if (filteredFiles.length + files.length > 3) {
      alert('You can only upload up to 3 files.');
      return;
    }

    const invalidChars = /[<>:"/\\|?*#\x00-\x1F]/g;
    const sanitizedFiles = filteredFiles.map(file => {
      const sanitizedFileName = file.name.replace(invalidChars, "");

      return new File([file], sanitizedFileName, {
        type: file.type,
        lastModified: file.lastModified,
      });
    });

    // setFiles([...files, ...filteredFiles]);
    setFiles([...files, ...sanitizedFiles]);
  };

  const addWorkingDays = (startDate, numberOfDays) => {
    let currentDate = startDate;
    let addedDays = 0;

    while (addedDays < numberOfDays) {
      currentDate = addDays(currentDate, 1);
      if (!isWeekend(currentDate)) {
        addedDays++;
      }
    }

    return currentDate;
  }

  const startGoBack = () => {
    history.push({ pathname: "/cart" });
  };

  const isWeekday = date => {
    return !isWeekend(date.toDate());
  };

  useEffect(() => {
    if (cartData && orderConfirmationData) {
      // console.log("cartData", cartData);
      // console.log("orderConfirmationData", orderConfirmationData);
    }
    const today = startOfDay(new Date());
    let minimumDaysRequired = 1; // Default minimum days

    const hasITE = some(cartData, item => item.Type === 'ITE');
    const hasCustomEarpiece = some(cartData, item => item.Type === 'CustomEarpiece');
    const hasStandard = some(cartData, item => item.Type === 'Standard');
    const hasStockOrder = some(cartData, item => item.Type === 'StockOrder');

    if (hasStockOrder) {
      const stockOrderData = find(orderConfirmationData, { Code: 'StockOrder' });
      const stockOrderMinimumDaysRequired = stockOrderData ? stockOrderData.MinimumDaysRequired : 1;
      minimumDaysRequired = Math.max(minimumDaysRequired, stockOrderMinimumDaysRequired);
    }

    if (hasITE) {
      const iteData = find(orderConfirmationData, { Code: 'ITE' });
      const iteMinimumDaysRequired = iteData ? iteData.MinimumDaysRequired : 1;
      minimumDaysRequired = Math.max(minimumDaysRequired, iteMinimumDaysRequired);
    }

    if (hasCustomEarpiece) {
      const customEarpieceData = find(orderConfirmationData, { Code: 'CustomEarpieces' });
      const customEarpieceMinimumDaysRequired = customEarpieceData ? customEarpieceData.MinimumDaysRequired : 1;
      minimumDaysRequired = Math.max(minimumDaysRequired, customEarpieceMinimumDaysRequired);
    }

    if (hasStandard) {
      const standardData = find(orderConfirmationData, { Code: 'Standard' });
      const standardMinimumDaysRequired = standardData ? standardData.MinimumDaysRequired : 1;
      minimumDaysRequired = Math.max(minimumDaysRequired, standardMinimumDaysRequired);
    }


    const minimumDate = addWorkingDays(today, minimumDaysRequired);
    setMinDate(format(minimumDate, 'yyyy-MM-dd'));
  }, [cartData, orderConfirmationData]);

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    // //console.log("profileData - ", profileData.Sites.length);
    if (loadOrderConfirmation) {
      loadOrderConfirmation();
    }
  }, [loadOrderConfirmation]);

  useEffect(() => {
    if (profileData) {
      //console.log("profileData - ", profileData.Sites);
      let tempSiteList = sortBy(profileData.Sites, [function (site) { return site.SiteName; }]);
      setSiteList(tempSiteList);
    }
  }, [profileData]);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Gets values from the form and sets to local variables
    const {
      purchaseOrderNumber,
      specialInstructions,
      discountCode,
      discountOption,
      // firstName,
      // lastName,
      // dateOfBirth,
      // requiredByDate,
      HAFSorder,
      tamperProofRequired,
      VAClient,
    } = document.forms[0];

    // Validate dateOfBirth
    if (!isStockOrder) {
      if (!dateOfBirth) {
        alert("Please enter a valid date of birth.");
        return;
      }

      const parsedDate = parseDate(dateOfBirth);
      if (!parsedDate) {
        alert("Please enter a valid date of birth.");
        return;
      }
      
      const currentDate = new Date();
      if (!isBefore(parsedDate, currentDate)) {
        alert("Date of birth must be before the current date.");
        return;
      }
    }

    // Validate requiredByDate
    const requiredByDateValue = requiredByDate ? moment(requiredByDate) : undefined;
    if (!requiredByDateValue || !requiredByDateValue.isValid()) {
      alert("Please enter a valid required by date.");
      return;
    }

    let tempSite = "";
    // console.log("siteList - ", siteList);
    if (siteList.length == 1) {
      tempSite = siteList[0].SiteCode;
    } else {
      setSiteError();
      const { site } = document.forms[0];
      if (site.value) {
        tempSite = siteList[site.value].SiteCode;
      } else {
        setSiteError("Please select a site");
        return;
      }
    }

    let tempCartData = [];
    cartData.map((item) => {
      item.ConfigurationGroup = JSON.stringify(item.ConfigurationGroup);
      item.CustomOptions = JSON.stringify(item.CustomOptions);
      item.Audiogram = JSON.stringify(item.Audiogram);
      tempCartData.push(item);
    });

    var orderType = "Standard";
    var isStock = false;
    if (isStockOrder === OrderStatus.STOCK_ORDER) {
      orderType = "Stock";
      isStock = true;
    }
    var HAFSorderChecked = false;
    var tamperProofRequiredChecked = false;
    var VAClientChecked = false;
    if (HAFSorder && HAFSorder.checked) {
      HAFSorderChecked = true;
    }
    if (tamperProofRequired && tamperProofRequired.checked) {
      tamperProofRequiredChecked = true;
    }
    if (VAClient && VAClient.checked) {
      VAClientChecked = true;
    }

    // Send order to API ./store/saga
    submitOrder(
      tempCartData,
      orderShippingRate,
      purchaseOrderNumber.value,
      specialInstructions.value,
      profileData.OrganisationCode,
      tempSite,
      firstName,
      lastName,
      // dateOfBirth.value,
      // requiredByDate.value,
      dateOfBirth,
      requiredByDate.format('YYYY-MM-DD'),
      HAFSorderChecked,
      tamperProofRequiredChecked,
      VAClientChecked,
      isStock,
      files,
      discountCode.value,
      discountOption.value,
      orderType,
    );
  };

  const goToSuccessScreen = () => {
    // console.log("order number in goToSuccessScreen 1- ", orderNumber);
    // console.log("sentOrderConfirmationStatus in goToSuccessScreen 1- ", sentOrderConfirmationStatus);
    if (sentOrderConfirmationStatus === OrderConfirmationStatus.SUCCESS && orderNumber) {
      // console.log("order number in goToSuccessScreen 2- ", orderNumber);
      // console.log("sentOrderConfirmationStatus in goToSuccessScreen 2- ", sentOrderConfirmationStatus);

      // let goToOrderDetailScreen = false;
      // if (isStockOrder != OrderStatus.STOCK_ORDER) {
      //   goToOrderDetailScreen = true;
      // }
      setIsStockOrder(OrderStatus.NONE);

      //Clear cart
      addOrderToCart();
      if (orderNumber) {
        let url = `/profile/order-history/${orderNumber}`;
        resetOrderNumber();
        loadOrderConfirmation();
        history.push({ pathname: `${url}` });
      }
      else {
        resetOrderNumber();
        history.push({ pathname: "/order-confirmation/success" });
      }
    }
  }


  const parseDate = (input) => {
    // Try different date formats here based on your requirements
    const formats = ['yyyy-MM-dd', 'yyyyMMdd', 'yyyy/MM/dd', 'yyyy.MM.dd', 'MM/dd/yyyy', 'MMddyyyy', 'MM-dd-yyyy', 'MM.dd.yyyy', 'dd/MM/yyyy', 'ddMMyyyy', 'dd-MM-yyyy', 'dd.MM.yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd', 'yyyy.MM.dd', 'dd/MM/yyyy', 'dd-MM-yyyy', 'dd.MM.yyyy', 'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX', 'yyyy-MM-dd\'T\'HH:mm:ss', 'yyyy-MM-dd HH:mm:ss', 'MMMM d, yyyy', 'd MMMM yyyy',];
    for (let f of formats) {
      const parsed = parse(input, f, new Date());
      if (isValid(parsed)) {
        // console.log("parsed", parsed)
        // console.log("format(parsed, 'dd-MM-yyyy')", format(parsed, 'dd-MM-yyyy'))

        return parsed;
      }
    }
    return null;
  };

  const handleDateOfBirthPaste = (e) => {
    const pastedText = e.clipboardData.getData('text');
    //console.log(pastedText);
    const parsedDate = parseDate(pastedText);
    if (parsedDate) {
      e.preventDefault();
      setDateOfBirth(format(parsedDate, 'yyyy-MM-dd'));
    } else {
      alert('Pasted date of birth is invalid');
    }
  };
  const handleRequiredByDatePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const parsedDate = parseDate(pastedText);
    const formatedDate = format(parsedDate, 'yyyy-MM-dd');

    // console.log("formatedDate", formatedDate)
    // console.log("minDate", minDate)

    if (parsedDate && !isWeekend(formatedDate) && (formatedDate >= minDate)) {
      //minDate check parsedDate is > minDate
      // console.log("moment(formatedDate)", moment(formatedDate))

      setRequiredByDate(moment(formatedDate));
    } else {
      alert('Pasted date is invalid or falls on a weekend.');
    }
  };

  const handleRequiredByDateChange = (newDate) => {
    // console.log("newDate", newDate)
    if (newDate && !isWeekend(newDate.toDate())) {
      setRequiredByDate(newDate);
    }
  };


  const handleFirstNameChange = (e) => {
    if (isStockOrder !== OrderStatus.STOCK_ORDER) {
      setFirstName(e.target.value);
    }
  };

  const handleLastNameChange = (e) => {
    if (isStockOrder !== OrderStatus.STOCK_ORDER) {
      setLastName(e.target.value);
    }
  };


  //If API returns a success show a success screen
  useEffect(() => {
    goToSuccessScreen();
  }, [sentOrderConfirmationStatus, orderNumber]);

  if (sentOrderConfirmationStatus === OrderConfirmationStatus.LOADING) {
    return (
      <Col>
        <div className="">
          <Loading />
        </div>
      </Col>
    );
  }


  return (
    <div className="order-confirmation-main-div">
      <div className="order-confirmation-title">
        <h1>Checkout</h1>
        <p>
          Please check the following information is correct and fill out the
          available fields if applicable.
        </p>
        <p>
        </p>
      </div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} className="order-confirmation-sub-heading">
            <h2>Processing Information</h2>
          </Col>
          <Col xs={5}>
            <table className="table-full-width">
              {profileData && profileData.FirstName && profileData.LastName && (
                <tr>
                  <th>Name</th>
                  <td>
                    {profileData.FirstName} {profileData.LastName}
                  </td>
                </tr>
              )}
              {profileData && profileData.OrganisationCode && (
                <tr>
                  <th>Organisation</th>
                  <td>{profileData.OrganisationName}</td>
                </tr>
              )}
              {siteList && siteList.length == 1 && (
                <tr>
                  <th>Site</th>
                  <td>{siteList[0] && siteList[0].SiteName}</td>
                </tr>
              )}
              <tr>
                <th>First Name *</th>
                <td>
                  <Form.Control
                    type="text"
                    name="firstName"
                    className="me-2"
                    aria-label="firstName"
                    required
                    onChange={handleFirstNameChange}
                    value={firstName}
                    readOnly={isStockOrder === OrderStatus.STOCK_ORDER}
                  />
                </td>
              </tr>
              <tr>
                <th>Last Name *</th>
                <td>
                  <Form.Control
                    type="text"
                    name="lastName"
                    className="me-2"
                    aria-label="lastName"
                    required
                    onChange={handleLastNameChange}
                    value={lastName}
                    readOnly={isStockOrder === OrderStatus.STOCK_ORDER}
                  />
                </td>
              </tr>
              {isStockOrder !== OrderStatus.STOCK_ORDER &&
                <tr>
                  <th>DOB *</th>
                  <td>
                    <Form.Control
                      type="date"
                      name="dateOfBirth"
                      className="me-2"
                      aria-label="dateOfBirth"
                      value={dateOfBirth}
                      onChange={(e) => setDateOfBirth(e.target.value)}
                      onPaste={handleDateOfBirthPaste}
                    />
                  </td>
                </tr>
              }
              <tr>
                <th>Required By *</th>
                <td>
                  {/* <Form.Control
                    type="date"
                    name="requiredByDate"
                    className="me-2"
                    aria-label="requiredByDate"
                    min={minDate}
                    value={requiredByDate}
                    onChange={handleRequiredByDateChange}
                    onPaste={handleRequiredByDatePaste}
                  /> */}
                  <div>
                    <div
                      // style={{ opacity: 0, height: 0, position: 'absolute', zIndex: -1 }}
                      onPaste={handleRequiredByDatePaste}
                    >
                      <SingleDatePicker
                        date={requiredByDate}
                        onDateChange={handleRequiredByDateChange}
                        focused={requiredByDateFocused}
                        onFocusChange={({ focused }) => setRequiredByDateFocused(focused)}
                        id="requiredByDate"
                        isDayBlocked={day => isWeekend(day.toDate())}
                        numberOfMonths={1}
                        isOutsideRange={day => day.isBefore(minDate)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>Purchase Order #</th>
                <td>
                  <Form.Control
                    type="text"
                    name="purchaseOrderNumber"
                    className="me-2"
                    aria-label="purchaseOrderNumber"
                    required={isHAFSorderChecked || isVAClientChecked}
                  />
                </td>
              </tr>
              {isStockOrder !== OrderStatus.STOCK_ORDER &&
                <tr>
                  <th>HAFS <br /><span className="hafs-title">{"("}Hearing Aid Funding Scheme{")"}</span></th>
                  <td>
                    <Form.Control
                      type="checkbox"
                      name="HAFSorder"
                      className="me-2 hafs-checkbox"
                      aria-label="HAFSorder"
                      onChange={handleHAFSorderChange}
                    />
                  </td>
                </tr>
              }
              {isStockOrder !== OrderStatus.STOCK_ORDER &&
                <tr>
                  <th>Tamper Proof Required</th>
                  <td>
                    <Form.Control
                      type="checkbox"
                      name="tamperProofRequired"
                      className="me-2 hafs-checkbox"
                      aria-label="tamperProofRequired"
                    />
                  </td>
                </tr>
              }
              {isStockOrder !== OrderStatus.STOCK_ORDER && !hideVACheckbox &&
                <tr>
                  <th>Veteran's Affairs {"("}VA{")"} Client<br /><span className="hafs-title">{"("}Please select when ordering specific product from VA price list, pricing PO number{")"}</span></th>
                  <td>
                    <Form.Control
                      type="checkbox"
                      name="VAClient"
                      className="me-2 hafs-checkbox"
                      aria-label="VAClient"
                      onChange={handleVAClientChange}
                    />
                  </td>
                </tr>
              }

              {isStockOrder !== OrderStatus.STOCK_ORDER &&
                <tr>
                  <th>File Upload<br /><span className="hafs-title">Please select up to 3 files. The files will be uploaded when you submit your order.</span></th>
                  <td className="file-upload-td">
                    <div class="fileUpload">
                      <input
                        type="file"
                        id="file-input"
                        className="file-input__input"
                        onChange={handleFileChange}
                        multiple
                        accept=".pdf,.png,.jpeg,.jpg,.stl,.asc"
                      />
                      <label className="file-input__label" for="file-input">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="upload"
                          className="svg-inline--fa fa-upload fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                          ></path>
                        </svg>
                        <span>Select File(s)</span>

                      </label>
                    </div>
                    {/* <button onClick={handleUpload} disabled={files.length === 0}>Upload Files</button> */}
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>
                          {file.name} <Button variant="danger ml-2" onClick={() => removeFile(index)}>Remove</Button>
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              }
              <tr>
                <td>
                  <p>*Required Field</p>
                </td>
              </tr>
            </table>
            {/* <p>{AccountId} / {AccountName}</p> */}
          </Col>

          <Col xs={12}>
            {siteList && siteList.length > 1 && (
              <table className="shipping-options-top-input">
                <tr>
                  <th>SITE:</th>
                  {siteList.map((site, index) => {
                    return (
                      <td>
                        <input
                          onChange={handleSiteChange}
                          id={`site-${index}`}
                          type="radio"
                          value={index}
                          checked={selectedSite == index}
                          name="site"
                        />
                        {site.SiteName}
                      </td>
                    );
                  })}
                </tr>
              </table>
            )}
            {siteError && (
              <div className="alert alert-danger">
                <p>{siteError}</p>
              </div>
            )}
          </Col>
        </Row>
        {/*
        <Row>
          <Col xs={12} className="order-confirmation-sub-heading">
            <h2>Shipping Information</h2>
          </Col>
          <Col md={8} xs={12} className="shipping-options-div">
            <Row>
              <Col xs={3}>
                <th className="pt-0">PURCHASE OPTIONS: </th>
              </Col>
              <Col xs={9}>
                <div className="shipping-options-top-input">
                  <input
                    onChange={handleChange}
                    id="shipping-rate-1"
                    type="radio"
                    value={"rush"}
                    name="shipping-rate"
                  />
                  Rush order 24h - with charge
                </div>
                <div>
                  <input
                    defaultChecked={true}
                    onChange={handleChange}
                    id="shipping-rate-2"
                    type="radio"
                    value={"standard"}
                    name="shipping-rate"
                  />
                  Standard order
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
           */}
        <Row className="order-summary-title-row pb-0">
          <Col xs={12} className="order-confirmation-sub-heading">
            <h2>Items</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Product Code</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {cartData &&
                  cartData.map((productInCart, index) => {
                    return (
                      <tr key={index}>
                        <th>
                          {productInCart.Name} {productInCart.Side}
                        </th>
                        <td>
                          {productInCart.Code &&
                            `Material #: ${productInCart.Code}`}
                        </td>
                        <td>
                          {productInCart.Quantity
                            ? `${productInCart.Quantity}`
                            : "1"}
                        </td>
                        {/* <Col xs={12}>
                     <Row className="pb-0">
                      <Col xs={3}>
                        <div className="product-in-cart-content">

                        </div>
                      </Col>
                      <Col md={6}>
                        <div>
                          <td></td>
                        </div>
                      </Col>
                      <Col xs={3}>
                        <td className="float-right">

                        </td>
                      </Col>
                    </Row>
                  </Col> */}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="order-summary-title-row">
          <Col
            xs={12}
            className="order-confirmation-sub-heading special-instructions-title"
          >
            <h2>Special Instructions for Order</h2>
          </Col>
          <Col xs={6} className="special-instructions-div">
            <table>
              <tr style={{ border: "none" }}>
                <th style={{ paddingLeft: "0" }}>Discount Serial Number</th>
                <td>
                  <Form.Control
                    type="text"
                    name="discountCode"
                    className="me-2"
                    aria-label="discountCode"
                  />
                </td>
              </tr>
            </table>
          </Col>
          <Col xs={6} className="special-instructions-div">
            <table>
              <tr style={{ border: "none" }}>
                <th style={{ paddingLeft: "0" }}>Selection</th>
                <td>
                  <Form.Control
                    type="text"
                    name="discountOption"
                    className="me-2"
                    aria-label="discountOption"
                  />
                </td>
              </tr>
            </table>

          </Col>
          <Col xs={12} className="special-instructions-div">
            <p>Additional Notes</p>
            <textarea
              id="specialInstructions"
              name="specialInstructions"
              rows="8"
            ></textarea>
          </Col>
          {/* <Form.Control
              type="message"
              name="specialInstructions"
              className="me-2"
              aria-label="specialInstructions"
            /> */}
        </Row>
        <Row>
          {sentOrderConfirmationStatus === OrderConfirmationStatus.ERROR && (
            <Col xs={12}>
              <div className="alert alert-danger">
                <p>
                  An error occured with submitting your order. Please try again
                </p>
              </div>
            </Col>
          )}
          <Col xs={2}>
            <Button onClick={startGoBack}>Back</Button>
          </Col>
          <Col xs={2} className="offset-8">
            <Button type="submit" className="btn btn-primary float-right">
              Submit Order
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

OrderConfirmation.propTypes = {
  submitOrder: PropTypes.func,
  orderConfirmationStatus: PropTypes.string,
  sentOrderConfirmationStatus: PropTypes.string,
  orderNumber: PropTypes.string,
  cartData: PropTypes.array,
  addOrderToCart: PropTypes.func,
  loadOrderConfirmation: PropTypes.func,
  resetOrderNumber: PropTypes.func,
  profileData: PropTypes.object,
};

export default OrderConfirmation;
